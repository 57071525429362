import React from "react"
import Behance from "../assets/img/icons/behance.inline.svg"
import Dribbble from "../assets/img/icons/dribbble.inline.svg"
import Facebook from "../assets/img/icons/facebook.inline.svg"
import Instagram from "../assets/img/icons/instagram.inline.svg"
import Linkedin from "../assets/img/icons/linkedin.inline.svg"
import Twitter from "../assets/img/icons/twitter.inline.svg"

const SocialMediaIcons = () => {
  return (
    <div className="flex items-center social-media">
      <a
        href="https://www.facebook.com/TechuzIT"
        rel="dofollow"
        target="_blank"
        aria-label="Facebook"
        className="text-[#888] text-[1.375rem] mr-5 hover:text-primary transition-all"
      >
        <Facebook className="svg-icons" />
      </a>
      <a
        href="https://www.twitter.com/TechuzIT"
        rel="dofollow"
        target="_blank"
        aria-label="Twitter"
        className="text-[#888] text-[1.375rem] mr-5 hover:text-primary transition-all"
      >
        <Twitter className="svg-icons" />
      </a>
      <a
        href="https://in.linkedin.com/company/techuz-infoweb-pvt-ltd"
        rel="dofollow"
        target="_blank"
        aria-label="LinkedIn"
        className="text-[#888] text-[1.375rem] mr-5 hover:text-primary transition-all"
      >
        <Linkedin className="svg-icons" />
      </a>
      <a
        href="https://dribbble.com/techuz"
        rel="dofollow"
        target="_blank"
        aria-label="Dribbble"
        className="text-[#888] text-[1.375rem] mr-5 hover:text-primary transition-all"
      >
        <Dribbble className="svg-icons" />
      </a>
      <a
        href="https://www.instagram.com/techuz/"
        rel="dofollow"
        target="_blank"
        aria-label="Instagram"
        className="text-[#888] text-[1.375rem] mr-5 hover:text-primary transition-all"
      >
        <Instagram className="svg-icons" />
      </a>
      <a
        href="https://www.behance.net/techuzinfoweb"
        rel="dofollow"
        aria-label="Behance"
        target="_blank"
        className="text-[#888] text-[1.375rem] mr-5 hover:text-primary transition-all"
      >
        <Behance className="svg-icons" />
      </a>
    </div>
  )
}
export default SocialMediaIcons
