module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"Techuz","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"17c8c5cd5bb35bb69ccbb3358958611b"},
    },{
      plugin: require('../plugins/gatsby-clutch-widget/gatsby-browser.js'),
      options: {"plugins":[],"include":["/hire-react-developers-usa/","/hire-react-developers-india/"]},
    },{
      plugin: require('../plugins/gatsby-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-17621942-2"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../plugins/gatsby-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T64T44K","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
