
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import Love from "../assets/img/love-icon.inline.svg"
import CorporateLocs from "./CorporateLocs"

const Footer = () => {
  const [isExpanded, setIsExpanded] = useState({})
  const getToggleMeClass = id => {
    setIsExpanded(prev => ({
      [id]: prev[id] === undefined ? true : !prev[id],
    }))
  }

  return (
    <footer className="bg-[#151516] pt-14">
      <div className="text-center container">
        <Link to="/" className="logo mb-6 w-full md:hidden block text-left">
          <StaticImage
            src="../assets/img/techuz-logo-white.png"
            alt="Techuz Infoweb"
            className="inline max-w-[6.563rem]"
            class="relative w-auto"
            width={105}
            height={35.45}
            placeholder="blurred"
          />
        </Link>
        <h4 className="text-white md:text-sm text-xs flex items-center md:justify-center justify-start leading-7 md:pb-6 pb-2.5">
          Made with
          <Love className="mx-1" />
          in India and serving worldwide
        </h4>
        <ul className="flex uppercase text-[#979797] md:justify-center justify-start text-xl font-gorditaregular flex-wrap md:flex-nowrap">
          <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
            USA
          </li>
          <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
            UK
          </li>
          <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
            Australia
          </li>
          <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
            SINGAPORE
          </li>
          <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
            INDIA
          </li>
          <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
            UAE
          </li>
          <li className="md:px-[0.625rem] md:mr-0 mr-2.5 pr-2.5 md:text-[1.125rem] text-[0.938rem]">
            EUROPE
          </li>
        </ul>
      </div>
      <CorporateLocs />
      <div className="md:pb-10 md:pt-16 pb-5 pt-8">
        <div className="container flex items-start justify-between lg:flex-nowrap flex-wrap">
          <div className="lg:w-1/6 md:w-3/12 w-full lg:mb-0 lg:mt-0 mb-4 md:max-w-[8.125rem] ">
            <Link to="/" className="logo mb-3 w-full hidden md:inline-block">
              <StaticImage
                src="../assets/img/techuz-logo-white.png"
                alt="Techuz Infoweb"
                className=" max-w-[6.563rem]"
                width={105}
                height={35.45}
                placeholder="blurred"
              />
            </Link>
            <ul className="flex flex-wrap md:flex-col">
              <li className="w-6/12 md:w-full md:my-0 my-1">
                <Link
                  to="/company/"
                  className="text-base md:text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Company
                </Link>
              </li>
              <li className="w-6/12 md:w-full md:my-0 my-1">
                <Link
                  to="/portfolio/"
                  className="text-base md:text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Work
                </Link>
              </li>
              <li className="w-6/12 md:w-full md:my-0 my-1">
                <Link
                  to="/careers/"
                  className="text-base md:text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Careers
                </Link>
              </li>
              <li className="w-6/12 md:w-full md:my-0 my-1">
                <a
                  href="/blog/"
                  className="text-base md:text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Resources
                </a>
              </li>
              <li className="w-6/12 md:w-full md:my-0 my-1">
                <Link
                  to="/contact-us/"
                  className="text-base md:text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="lg:w-1/6 md:w-4/12 w-full my-4 lg:my-0 md:mt-0 mb-4 md:max-w-[8.125rem]">
            <h5
              className={`md:text-base text-2xl font-blancomedium text-white lg:pb-5 md:pb-1 pb-0 relative md:after:hidden md:before:hidden ${isExpanded["webDev"]
                ? "after:content-['-'] after:right-[12px]"
                : "after:content-['+'] after:right-[0.625rem]"
                } after:font-bold after:text-xl after:absolute after:leading-4 after:text-orangecol after:top-[0.375rem] before:content[''] before:absolute before:w-8 before:h-8 before:rounded-full before:right-0 before:opacity-5 before:bg-white-100`}
              onClick={() => getToggleMeClass("webDev")}
            >
              <Link to="/web-development/">
                AI
                <span className="bg-orangecol w-1 h-1 rounded-full inline-block ml-1" />
              </Link>
            </h5>
            <ul
              className={`${isExpanded["webDev"] ? "block" : "hidden"
                } md:flex flex-wrap flex-col`}
            >
              <li>
                <Link
                  to="/generative-ai-development-company/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Gen AI
                </Link>
              </li>
              <li>
                <Link
                  to="/llm-development-company/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Custom LLM
                </Link>
              </li>
              <li>
                <Link
                  to="/machine-learning-development-company/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  ML Development
                </Link>
              </li>
              <li>
                <Link
                  to="/data-science-consulting-services"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Data Science Consulting
                </Link>
              </li>
            </ul>
          </div>
          <div className="lg:w-1/6 md:w-4/12 w-full my-4 lg:my-0 md:mt-0 mb-4 md:max-w-[8.125rem]">
            <h5
              className={`md:text-base text-2xl font-blancomedium text-white lg:pb-5 md:pb-1 pb-0 relative md:after:hidden md:before:hidden ${isExpanded["mobileDev"]
                ? "after:content-['-'] after:right-[12px]"
                : "after:content-['+'] after:right-[0.625rem]"
                } after:font-bold after:text-xl after:absolute after:leading-4 after:text-orangecol after:top-[0.375rem] before:content[''] before:absolute before:w-8 before:h-8 before:rounded-full before:right-0 before:opacity-5 before:bg-white-100`}
              onClick={() => getToggleMeClass("mobileDev")}
            >
              <Link to="/mobile-application-development/">
                EdTech
                <span className="bg-orangecol w-1 h-1 rounded-full inline-block ml-1" />
              </Link>
            </h5>
            <ul
              className={`${isExpanded["mobileDev"] ? "block" : "hidden"
                } md:flex flex-wrap flex-col`}
            >
              <li>
                <Link
                  to="/lms-development-company/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Custom LMS
                </Link>
              </li>
              <li>
                <Link
                  to="/ai-in-edtech/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  AI Integration
                </Link>
              </li>
              <li>
                <Link
                  to="/education-app-development-company/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Mobile Learning
                </Link>
              </li>
              <li>
                <Link
                  to="/video-platform-for-education/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Live Video Trainings
                </Link>
              </li>
              <li>
                <Link
                  to="/custom-eLearning-development/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Custom eLearning
                </Link>
              </li>
              <li>
                <Link
                  to="/microlearning-app-development-company/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Microlearning
                </Link>
              </li>
            </ul>
          </div>

          <div className="lg:w-1/6 md:w-4/12 w-full my-4 md:my-0 md:max-w-[8.125rem]">
            <h5
              className={`md:text-base text-2xl font-blancomedium text-white lg:pb-5 md:pb-1 pb-0 relative md:after:hidden md:before:hidden ${isExpanded["cloud"]
                ? "after:content-['-'] after:right-[12px]"
                : "after:content-['+'] after:right-[0.625rem]"
                } after:font-bold after:text-xl after:absolute after:leading-4 after:text-orangecol after:top-[0.375rem] before:content[''] before:absolute before:w-8 before:h-8 before:rounded-full before:right-0 before:opacity-5 before:bg-white-100`}
              onClick={() => getToggleMeClass("cloud")}
            >
              Services
              <span className="bg-orangecol w-1 h-1 rounded-full inline-block ml-1" />
            </h5>
            <ul
              className={`${isExpanded["cloud"] ? "block" : "hidden"
                } md:flex flex-wrap flex-col`}
            >
              <li>
                <Link
                  to="/software-development-company/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Software Development
                </Link>
              </li>
              <li>
                <Link
                  to="/mobile-application-development/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Mobile App Development
                </Link>
              </li>
              <li>
                <Link
                  to="/full-cycle-product-development/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Full Cycle Product Development
                </Link>
              </li>
              <li>
                <Link
                  to="/web-development/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Web Development
                </Link>
              </li>
              <li>
                <Link
                  to="/ui-ux-design-company/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  UI/UX Design
                </Link>
              </li>
              <li>
                <Link
                  to="/saas-development-company/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  SaaS Product Development
                </Link>
              </li>
            </ul>
          </div>
          <div className="lg:w-1/6 md:w-4/12 w-full my-4 md:my-0 md:max-w-[8.125rem]">
            <h5
              className={`md:text-base text-2xl font-blancomedium text-white lg:pb-5 md:pb-1 pb-0 relative md:after:hidden md:before:hidden ${isExpanded["trending"]
                ? "after:content-['-'] after:right-[12px]"
                : "after:content-['+'] after:right-[0.625rem]"
                } after:font-bold after:text-xl after:absolute after:leading-4 after:text-orangecol after:top-[0.375rem] before:content[''] before:absolute before:w-8 before:h-8 before:rounded-full before:right-0 before:opacity-5 before:bg-white-100`}
              onClick={() => getToggleMeClass("trending")}
            >
              Technology
              <span className="bg-orangecol w-1 h-1 rounded-full inline-block ml-1" />
            </h5>
            <ul
              className={`${isExpanded["trending"] ? "block" : "hidden"
                } md:flex flex-wrap flex-col`}
            >
              <li>
                <Link
                  to="/angular-js-development/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Angular JS
                </Link>
              </li>
              <li>
                <Link
                  to="/react-js-development/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  React JS
                </Link>
              </li>
              <li>
                <Link
                  to="/vue-js-development/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Vue.js
                </Link>
              </li>
              <li>
                <Link
                  to="/node-js-development/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Node.js
                </Link>
              </li>
              <li>
                <Link
                  to="/net-core-development-company/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  .NET Core
                </Link>
              </li>
              <li>
                <Link
                  to="/python-development-company/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Python
                </Link>
              </li>
              <li>
                <Link
                  to="/flutter-app-development/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Flutter
                </Link>
              </li>
              <li>
                <Link
                  to="/aws-development-services/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  AWS
                </Link>
              </li>
            </ul>
          </div>
          <div className="lg:w-1/6 md:w-4/12 w-full my-4 md:my-0 md:max-w-[8.125rem]">
            <h5
              className={`md:text-base text-2xl font-blancomedium text-white lg:pb-5 md:pb-1 pb-0 relative md:after:hidden md:before:hidden ${isExpanded["hireTalent"]
                ? "after:content-['-'] after:right-[12px]"
                : "after:content-['+'] after:right-[0.625rem]"
                } after:font-bold after:text-xl after:absolute after:leading-4 after:text-orangecol after:top-[0.375rem]  before:content[''] before:absolute before:w-8 before:h-8 before:rounded-full before:right-0 before:opacity-5 before:bg-white-100`}
              onClick={() => getToggleMeClass("hireTalent")}
            >
              Hire a talent
              <span className="bg-orangecol w-1 h-1 rounded-full inline-block ml-1" />
            </h5>
            <ul
              className={`${isExpanded["hireTalent"] ? "block" : "hidden"
                } md:flex flex-wrap flex-col`}
            >
              <li>
                <Link
                  to="/hire-react-developers/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  ReactJS Developers
                </Link>
              </li>
              <li>
                <Link
                  to="/hire-node-developers/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Node.js Developers
                </Link>
              </li>
              <li>
                <Link
                  to="/hire-flutter-developers/"
                  className="text-sm inline-block w-auto text-[#979797] leading-7 hover:text-white transition-all duration-500"
                >
                  Flutter Developers
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="logo container flex flex-wrap items-center justify-center md:pb-14 pb-4">
        <ul className="flex items-center justify-center flex-wrap md:flex-nowrap">
          <li className="lg:mr-11 mr-7 my-4 md:my-0">
            <a
              href="https://www.upwork.com/agencies/~01181765b1178a4dc1"
              target="_blank"
              rel="nofollow"
            >
              <StaticImage
                src="../assets/img/upwork1.png"
                alt="upwork"
                placeholder="blurred"
              />
            </a>
          </li>
          <li className="lg:mr-11 mr-7 my-4 md:my-0">
            <a href="https://www.designrush.com/agency/profile/techuz" target="_blank" rel="nofollow">
              <StaticImage
                src="../assets/img/design-rush.inline.png"
                alt="Designrush"
                placeholder="blurred"
                className="w-[30px]"
              />
            </a>
          </li>
          <li className="lg:mr-11 mr-7 my-4 md:my-0">
            <a
              href="https://www.goodfirms.co/companies/view/1558/techuz-infoweb-private-limited"
              target="_blank"
              rel="nofollow"
            >
              <StaticImage
                src="../assets/img/goodfirms.png"
                alt="Goodfirms-web-logo"
                placeholder="blurred"
              />
            </a>
          </li>
          <li className="lg:mr-11 mr-7 my-4 md:my-0">
            <a href="https://clutch.co/profile/techuz-infoweb" target="_blank" rel="nofollow">
              <StaticImage
                src="../assets/img/cluch-review.webp"
                alt="Cluch review"
                placeholder="blurred"
                className="max-w-[12rem]"
              />
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-btm md:bg-[#09090A] bg-[#09090A] md:py-4 pt-4 pb-[0.875rem]">
        <div className="container">
          <div className="flex md:justify-between justify-center items-center flex-wrap md:flex-nowrap">
            <ul className="flex items-center md:mb-0 mb-3.5">
              <li className="leading-4 relative mr-4 pr-4 after:content[''] after:absolute after:bg-grey after:w-[0.063rem] after:h-3 after:top-1/2 after:right-0 after:translate-y-[-50%]">
                <Link
                  to="/privacy-policy/"
                  className="block text-[#808080] text-xs hover:text-white transition-all duration-500"
                >
                  Privacy Policy
                </Link>
              </li>
              <li className="leading-4">
                <Link
                  to="/terms/"
                  className="block text-[#808080] text-xs hover:text-white transition-all duration-500"
                >
                  Terms &amp; Conditions
                </Link>
              </li>
            </ul>
            <p className="text-[#808080] text-xs md:mb-0 md:w-auto w-full text-center">
              Copyright {new Date().getFullYear()} © Techuz InfoWeb Private
              Limited
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
