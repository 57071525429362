const appendClutchScript = () => {
  const scriptElement = document.createElement("script")
  scriptElement.src = "https://widget.clutch.co/static/js/widget.js"
  scriptElement.type = "text/javascript"
  scriptElement.id = "techuz-clutch"
  scriptElement.async = true
  document.body.appendChild(scriptElement)
  // Variable set to reference as a check that callback ran
  sessionStorage.setItem("loadedClutchWidget", "true")
}

const removeScript = () => {
  const script = document.getElementById("techuz-clutch")
  if (script) {
    script.remove()
  }
  sessionStorage.removeItem("loadedClutchWidget")
}

export const onInitialClientRender = (_, pluginOptions) => {
  const clutchScriptAvailable = Object.isExtensible(
    document.getElementById("techuz-clutch")
  )
  if (
    pluginOptions.include.includes(window.location.pathname) &&
    clutchScriptAvailable
  )
    sessionStorage.setItem("loadedClutchWidget", "true")
}

export const onRouteUpdate = ({ prevLocation, location }, pluginOptions) => {
  const addClutchScript = Object.is(
    document.getElementById("techuz-clutch"),
    null
  )
  const checkLoadedScripts = sessionStorage.getItem("loadedClutchWidget")
  const shouldInclude = pluginOptions.include.includes(location.pathname)
  if (prevLocation && !checkLoadedScripts && shouldInclude && addClutchScript) {
    appendClutchScript()
  }
  if (!!checkLoadedScripts && !shouldInclude) {
    removeScript()
  }
}
