import { Link, graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import BlackArrow from "../assets/img/down-arrow-black.inline.svg"
import TechuzLogoBlack from "../assets/img/techuz-logo.png"
import TechuzLogoWhite from "../assets/img/techuz-logo-white.png"
import { useContextValues } from "../context/NavBarContext"

import { StaticImage } from "gatsby-plugin-image"
import SocialMediaIcons from "./SocialMediaIcons"
import ArrowWhite from "../assets/img/arrow-right.webp"


const Header = () => {
  let {
    allTechnologiesNewJson: { technologies },
    allEdtechJson: { edtechs },
    // allTechnologiesJson: { technologies },
    // allTechnologiesExtraJson: { technologiesExtra },
    allServicesJson: { services }
  } = useStaticQuery(graphql`
    query {
      allTechnologiesNewJson {
        technologies: nodes {
          slug
          pageType
        }
      }
      allEdtechJson {
        edtechs: nodes {
          slug
        }
      }
      allServicesJson {
        services: nodes {
          slug
        }
      }
    }
  `)

  const templatePageSlugs = [] as Array<{ slug: string, pageType: string }>
  technologies.map(({ slug, pageType }) => templatePageSlugs.push({ slug, pageType }))
  edtechs.map(({ slug }) => templatePageSlugs.push({ slug, pageType: 'edtech' }))
  services.map(({ slug }) => templatePageSlugs.push({ slug, pageType: 'services' }))
  // technologiesExtra.map(({ slug }) => templatePageSlugs.push(slug))

  const [isShown, setIsShown] = useState<'ai' | 'edtech' | 'services' | 'technologies' | ''>('')
  const [showMenu, setShowMenu] = useState(false)
  const [isExpanded, setIsExpanded] = useState({})
  const [selectedCategory, setSelectedCategory] = useState({})
  const { isMobile, navColor, navPosition, changeNavPosition, currentPath, absoluteNav } =
    useContextValues()
  const [colorChange, setColorchange] = useState(false)

  const { pathname } = useLocation()

  const isWhiteNav = useMemo(
    () => (navColor === "white" ? true : false),
    [navColor]
  )
  const toggleMenu = () => {
    if (isMobile) {
      setShowMenu(prev => !prev)
      document.body.style.overflowY = showMenu ? 'auto' : 'hidden'
      if (currentPath === '/') {
        changeNavPosition(showMenu ? 'absolute' : 'fixed')
      }
    }
    setIsShown('')
    setIsExpanded({})
    setSelectedCategory({})
  }
  const getToggleMeClass = id => {
    setIsExpanded({
      [id]: isExpanded[id] === undefined ? true : !isExpanded[id],
    })
  }
  const getToggleCategoryClass = id => {
    setSelectedCategory({
      [id]: selectedCategory[id] === undefined ? true : !selectedCategory[id],
    })
  }
  const menuClass = useMemo(() => {
    if (showMenu && isMobile) {
      return "z-999 fixed left-0 top-0 w-full h-full z-10 md:static md:w-auto md:h-auto md:block bg-white-200 md:bg-transparent overflow-y-scroll md:overflow-y-hidden overflow-x-hidden menu-open"
    }
    return "z-999 fixed w-full h-full z-10 md:static md:w-auto md:h-auto md:block hidden bg-white-200 md:bg-transparent overflow-y-scroll md:overflow-y-visible"
  }, [showMenu, isMobile])

  const activePageClass = useCallback((page: 'company' | 'ai' | 'edtech' | 'services' | 'technology' | 'portfolio') => {
    if (page === 'technology' || page === 'services' || page === 'edtech' || page === 'ai') {
      return templatePageSlugs.some(({ slug, pageType }) => page === pageType && pathname.includes(slug + '/')) ? 'before:!w-[calc(100%-17px)] !text-primary green-icon' : ''
    }
    return pathname.includes('/' + page + '/') ? 'before:!w-full !text-primary green-icon' : ''
  }, [pathname])

  const changeNavbarColor = () => {
    setColorchange(window.scrollY >= 60)
  }

  useEffect(() => {
    const bindChangeNavBar = !absoluteNav.includes(currentPath)
    bindChangeNavBar &&
      window.addEventListener("scroll", changeNavbarColor, { passive: true })
    return () => {
      setColorchange(false)
      bindChangeNavBar &&
        window.removeEventListener("scroll", changeNavbarColor)
    }
  }, [currentPath])

  return (
    <header
      className={`py-4 ${navPosition} w-full z-20 ${colorChange ? " bg-white-200" : "bg-transparent"
        } ${showMenu ? '!bg-white-200 top-0' : ''}`}
    >
      <div className="container flex items-center lg:px-[1.875rem] px-4">
        <div className="logo md:max-w-[6.25rem] lg:max-w-[8.625rem] lg:mr-[3.875rem] mr-3 relative z-20">
          <Link to="/" className={!showMenu ? undefined : "hidden"}>
            <img
              src={
                colorChange || !isWhiteNav ? TechuzLogoBlack : TechuzLogoWhite
              }
              width="110"
              height="32"
              alt="Techuz Infoweb"
            />
          </Link>
          <Link
            to="/"
            onClick={toggleMenu}
            className={` ${showMenu ? undefined : "hidden"}`}
          >
            <img
              src={TechuzLogoBlack}
              alt="Techuz Infoweb"
              className="max-w-[110px] object-contain"
              width="110"
              height="31.89"
            />
          </Link>
        </div>
        <div className="menu flex-1 z-999 justify-end inline-flex max-[767px]:pr-12">
          <button
            type="button"
            onClick={toggleMenu}
            aria-label="menu"
            className={`icon md:hidden block w-9 h-9 rounded-full border ${colorChange || !isWhiteNav || showMenu ? 'border-black' : 'border-white'} absolute right-4 top-4 z-50`}
          >
            <span className={`absolute w-4 h-0.5 left-0 right-0 mx-auto before:absolute before:content-[''] before:w-4 before:h-0.5 before:left-0 before:right-0 before:mx-auto after:absolute after:content-[''] after:w-4 after:h-0.5 after:-bottom-[0.350rem] after:left-0 after:right-0 after:mx-auto ${showMenu ? 'rotate-[45deg] before:rotate-[-90deg] before:-top-0 after:opacity-0' : 'before:-top-[0.350rem]'} ${colorChange || !isWhiteNav || showMenu ? 'bg-black before:bg-black after:bg-black' : 'bg-white-200 before:bg-white-200 after:bg-white-200'}`} />
          </button>

          <div className={`z-999 ${menuClass}`}>
            <nav className="flex items-center justify-end md:flex-nowrap flex-wrap md:p-0 py-10 pt-[6.5rem] px-4 nav-bar">
              <ul className="flex items-center md:flex-nowrap flex-wrap md:w-auto w-full">

                <li
                  onMouseEnter={() => setIsShown('ai')}
                  onMouseLeave={() => setIsShown('')}
                  className="group menu-item relative lg:px-4 md:px-2 md:my-0 my-3.5 md:py-[10px] md:text-center text-left md:w-auto w-full"
                >
                  <span
                    className=" md:text-black text-white md:text-base text-2xl font-gorditamedium group flex items-center"
                    onClick={() => getToggleCategoryClass("ai")}
                  >
                    <span
                      className={`text-black cursor-pointer flex items-center after:relative after:ml-3 after:text-black md:after:hidden after:block relative before:content[''] before:absolute before:left-0 before:bottom-[-11px] before:w-0 before:h-[4px] before:bg-primary hover:before:w-[calc(100%-17px)] max-md:before:hidden  ${selectedCategory["ai"]
                        ? "after:content-['-']"
                        : "after:content-['+']"
                        } ${colorChange || !isWhiteNav
                          ? " md:text-black"
                          : "md:text-white"
                        } ${activePageClass('ai')}`}
                    >
                      AI
                      <span className={`hidden md:flex ${colorChange || !isWhiteNav ? "" : "menu-arrow-white"}`}>
                        <BlackArrow className="ml-1.5 w-3 h-2 hidden md:block max-w-[0.688rem]" />
                      </span>
                    </span>
                  </span>
                  {(isShown === 'ai' || (isMobile && selectedCategory["ai"])) && (
                    <div
                      className={`transition-all duration-700 group-hover:opacity-100 group-hover:pointer-events-auto
                        md:opacity-0 opacity-100 z-10  md:w-[40.625rem] lg:w-[62rem] md:bg-[#fff] md:drop-shadow-[0px_32px_83px_rgba(0,0,0,0.07)] ${selectedCategory["ai"] ? "block" : "hidden"
                        } md:block top-[2.8rem] rounded-[1.25rem] md:absolute md:left-1/2 py-0 md:pl-[15px] lg:pl-10  md:px-0 md:before:[''] md:before:block before:hidden before:absolute before:top-[-10px] before:left-1/2 before:translate-x-[-100%] before:w-0 before:h-0 before:border-l-[10px] before:border-l-transparent before:border-r-[10px] before:border-r-transparent before:border-b-[10px] before:border-b-[#fff] overflow-hidden md:translate-x-[-100px] lg:translate-x-[-240px]`}
                    >
                      <div
                        className={`md:block text-left ${selectedCategory["ai"] ? "block" : "hidden"
                          }`}
                      >
                        <div className="main-grid grid lg:grid-cols-[auto_292px] gap-[2.375rem]">
                          <div className="left py-5 md:py-10">
                            <div className="md:w-auto w-full md:min-w-[30%] md:grid grid-cols-2 gap-y-[2rem] gap-x-[3.75rem]">
                              <Link to="/generative-ai-development-company/" className="grid grid-cols-[28px_auto] gap-4 rounded-md p-3 group/ai1 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/gen-ai-menu.inline.svg"
                                    alt="Software Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:content-[''] before:w-0 before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/ai1:before:opacity-100  group-hover/ai1:before:duration-[0.4s] group-hover/ai1:before:ease-in-out group-hover/ai1:before:w-full">Gen AI</h3>
                                  <p className="text-sm leading-[1.5]">Unlock innovation with our generative AI consulting</p>
                                </div>
                              </Link>

                              <Link to="/ai-development-company/" className="grid grid-cols-[28px_auto] gap-4 rounded-md p-3 group/ai2 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/ai-menu.inline.svg"
                                    alt="Web Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/ai2:before:opacity-100  group-hover/ai2:before:duration-[0.4s] group-hover/ai2:before:ease-in-out group-hover/ai2:before:w-full">AI Development</h3>
                                  <p className="text-sm leading-[1.5]">Harness advanced AI solutions engineered by our experts</p>
                                </div>
                              </Link>

                              <Link to="/llm-development-company/" className="grid grid-cols-[28px_auto] gap-4 group/ai3 rounded-md p-3 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/custom-llm-menu.inline.svg"
                                    alt="Mobile App Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/ai3:before:opacity-100  group-hover/ai3:before:duration-[0.4s] group-hover/ai3:before:ease-in-out group-hover/ai3:before:w-full">Custom LLM</h3>
                                  <p className="text-sm leading-[1.5]">Experience tailored language models crafted to specifications</p>
                                </div>
                              </Link>

                              <Link to="/data-science-consulting-services/" className="grid grid-cols-[28px_auto] gap-4 group/ai4 rounded-md p-3 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/data-science-menu.inline.svg"
                                    alt="UI/UX Design"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/ai4:before:opacity-100  group-hover/ai4:before:duration-[0.4s] group-hover/ai4:before:ease-in-out group-hover/ai4:before:w-full">Data Science Consulting</h3>
                                  <p className="text-sm leading-[1.5]">Transform data into insights through our expert consulting.</p>
                                </div>
                              </Link>

                              <Link to="/machine-learning-development-company/" className="grid grid-cols-[28px_auto] gap-4 rounded-md p-3 group/ai5 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/ml-menu.inline.svg"
                                    alt="Full Cycle Product Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/ai5:before:opacity-100  group-hover/ai5:before:duration-[0.4s] group-hover/ai5:before:ease-in-out group-hover/ai5:before:w-full">ML Development</h3>
                                  <p className="text-sm leading-[1.5]">Achieve intelligent automation with our ML development expertise.</p>
                                </div>
                              </Link>

                            </div>
                            <p className="max-md:hidden flex flex-wrap items-baseline justify-start gap-[0.25rem] text-xs leading-[0.9375rem] mt-10 pt-[14px] border-t border-[#EAEAEA]">
                              <StaticImage
                                src="../assets/img/clutch.webp"
                                alt="Clutch"
                                placeholder="blurred"
                                className="inline-block w-[3.875rem] align-middle max-sm:mr-[0.3125rem]"
                              />
                              <span className="font-gorditamedium max-sm:align-middle max-sm:mr-[0.3125rem]">4.9/5</span>
                              <StaticImage
                                src="../assets/img/rating-stars.webp"
                                alt="Reviewed rating"
                                placeholder="blurred"
                                className="inline-block w-[3.875rem] align-middle ml-[0.375rem] "
                              />
                              based on <span className="font-gorditamedium">43</span> verified reviews
                            </p>
                          </div>
                          <div className="lg:block hidden right bg-[#1E1E1E] pt-10 px-10 pb-[4.375rem]">
                            <span className="inline-block w-[13.1875rem] h-[13.125rem]">
                              <StaticImage
                                src="../assets/img/ai-menu.webp"
                                alt="Project Banner"
                                placeholder="blurred"
                                className="w-full h-full rounded-[0.625rem]"
                              />
                            </span>
                            <p className="text-base font-gorditamedium leading-[1.5] mb-[0.625rem] text-white">AI-Powered Legal Assistance for Divorce</p>
                            <p className="text-sm leading-[1.5] mb-5 text-white">AI-powered app for managing case history, understanding rights, and receiving tailored legal guidance.</p>

                            <div className="link-green inline-flex flex-wrap items-center relative gap-[0.625rem] mt-4 group transition duration-500 ease-linear">
                              <Link to="/portfolio/ai-solution-for-legal-help-in-divorce/" className="lg:text-sm relative leading-[1.5] italic font-gorditamedium text-[#4BD965] before:content-[''] before:w-full before:h-[1px] before:bg-[#4BD965] before:absolute before:left-0 before:bottom-0 " onClick={toggleMenu}>Read entire case study
                              </Link>
                              <img
                                src={ArrowWhite}
                                alt=""
                                className="w-[0.4375rem] group-hover:translate-x-1 transition duration-500 ease-linear"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </li>


                <li
                  onMouseEnter={() => setIsShown('edtech')}
                  onMouseLeave={() => setIsShown('')}
                  className="group menu-item relative lg:px-4 md:px-2 md:my-0 my-3.5 md:py-[10px] md:text-center text-left md:w-auto w-full"
                >
                  <span
                    className=" md:text-black text-white md:text-base max-sm:text-[22px] hover:text-primary font-gorditamedium group flex items-center"
                    onClick={() => getToggleCategoryClass("edtech")}
                  >
                    <span
                      className={`text-black cursor-pointer flex items-center after:relative after:ml-3 after:text-black md:after:hidden after:block relative before:content[''] before:absolute before:left-0 before:bottom-[-11px] before:w-0 before:h-[4px] before:bg-primary hover:before:w-[calc(100%-17px)] max-md:before:hidden ${selectedCategory["edtech"]
                        ? "after:content-['-']"
                        : "after:content-['+']"
                        } ${colorChange || !isWhiteNav
                          ? " md:text-black"
                          : "md:text-white"
                        } ${activePageClass('edtech')}`}
                    >
                      Edtech
                      <span className={`hidden md:flex ${colorChange || !isWhiteNav ? "" : "menu-arrow-white"}`}>
                        <BlackArrow className="ml-1.5 w-3 h-2 hidden md:block max-w-[0.688rem]" />
                      </span>
                    </span>
                  </span>
                  {(isShown === 'edtech' || (isMobile && selectedCategory["edtech"])) && (
                    <div
                      className={`transition-all duration-700 group-hover:opacity-100 group-hover:pointer-events-auto
                        md:opacity-0 opacity-100 z-10  md:w-[40.625rem] lg:w-[62rem] md:bg-[#fff] md:drop-shadow-[0px_32px_83px_rgba(0,0,0,0.07)] ${selectedCategory["edtech"] ? "block" : "hidden"
                        } md:block top-[2.8rem] rounded-[1.25rem] md:absolute md:left-1/2 py-0 md:pl-[15px] lg:pl-10  md:px-0 md:before:[''] md:before:block before:hidden before:absolute before:top-[-10px] before:left-1/2 before:translate-x-[-100%] before:w-0 before:h-0 before:border-l-[10px] before:border-l-transparent before:border-r-[10px] before:border-r-transparent before:border-b-[10px] before:border-b-[#fff] overflow-hidden md:translate-x-[-170px] lg:translate-x-[-340px] `}
                    >
                      <div
                        className={`md:block text-left ${selectedCategory["edtech"] ? "block" : "hidden"
                          }`}
                      >
                        <div className="main-grid grid lg:grid-cols-[auto_292px] gap-[2.375rem]">
                          <div className="left py-5 md:py-10">
                            <div className="md:w-auto w-full md:min-w-[30%] md:grid grid-cols-2 gap-y-[2rem] gap-x-[3.75rem]">
                              <Link to="/lms-development-company/" className="grid grid-cols-[28px_auto] gap-4 rounded-md p-3 group/edtech1 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/custom-lms-menu.inline.svg"
                                    alt="Software Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/edtech1:before:opacity-100  group-hover/edtech1:before:duration-[0.4s] group-hover/edtech1:before:ease-in-out group-hover/edtech1:before:w-full">Custom LMS</h3>
                                  <p className="text-sm leading-[1.5]">Personalized platforms for seamless online education management</p>
                                </div>
                              </Link>

                              <Link to="/video-platform-for-education/" className="grid grid-cols-[28px_auto] gap-4 rounded-md p-3 group/edtech2 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/live-video-menu.inline.svg"
                                    alt="Web Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/edtech2:before:opacity-100  group-hover/edtech2:before:duration-[0.4s] group-hover/edtech2:before:ease-in-out group-hover/edtech2:before:w-full">Live Video Trainings</h3>
                                  <p className="text-sm leading-[1.5]">Real-time, interactive training sessions for dynamic learning.</p>
                                </div>
                              </Link>

                              <Link to="/ai-in-edtech/" className="grid grid-cols-[28px_auto] gap-4 group/edtech3 rounded-md p-3 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/gen-ai-menu.inline.svg"
                                    alt="Software Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/edtech3:before:opacity-100  group-hover/edtech3:before:duration-[0.4s] group-hover/edtech3:before:ease-in-out group-hover/edtech3:before:w-full">AI Integration</h3>
                                  <p className="text-sm leading-[1.5]">Enhancing education with intelligent, adaptive AI-driven solutions</p>
                                </div>
                              </Link>

                              <Link to="/custom-eLearning-development/" className="grid grid-cols-[28px_auto] gap-4 group/edtech4 rounded-md p-3 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/custom-elearning-menu.inline.svg"
                                    alt="UI/UX Design"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/edtech4:before:opacity-100  group-hover/edtech4:before:duration-[0.4s] group-hover/edtech4:before:ease-in-out group-hover/edtech4:before:w-full">Custom eLearning</h3>
                                  <p className="text-sm leading-[1.5]">Tailored digital courses for immersive learning experiences.</p>
                                </div>
                              </Link>

                              <Link to="/education-app-development-company/" className="grid grid-cols-[28px_auto] gap-4 rounded-md p-3 group/edtech5 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/mobile-learning-menu.inline.svg"
                                    alt="Full Cycle Product Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/edtech5:before:opacity-100  group-hover/edtech5:before:duration-[0.4s] group-hover/edtech5:before:ease-in-out group-hover/edtech5:before:w-full">Mobile Learning</h3>
                                  <p className="text-sm leading-[1.5]">On-the-go education through responsive mobile platforms.</p>
                                </div>
                              </Link>

                              <Link to="/microlearning-app-development-company/" className="grid grid-cols-[28px_auto] gap-4 rounded-md p-3 group/edtech6 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/microlearning-menu.inline.svg"
                                    alt="Full Cycle Product Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/edtech6:before:opacity-100  group-hover/edtech6:before:duration-[0.4s] group-hover/edtech6:before:ease-in-out group-hover/edtech6:before:w-full">Microlearning</h3>
                                  <p className="text-sm leading-[1.5]">Bite-sized lessons for quick, effective skill development.</p>
                                </div>
                              </Link>

                            </div>
                            <p className="max-md:hidden flex flex-wrap items-baseline justify-start gap-[0.25rem] text-xs leading-[0.9375rem] mt-10 pt-[14px] border-t border-[#EAEAEA]">
                              <StaticImage
                                src="../assets/img/clutch.webp"
                                alt="Clutch"
                                placeholder="blurred"
                                className="inline-block w-[3.875rem] align-middle max-sm:mr-[0.3125rem]"
                              />
                              <span className="font-gorditamedium max-sm:align-middle max-sm:mr-[0.3125rem]">4.9/5</span>
                              <StaticImage
                                src="../assets/img/rating-stars.webp"
                                alt="Reviewed rating"
                                placeholder="blurred"
                                className="inline-block w-[3.875rem] align-middle ml-[0.375rem] "
                              />
                              based on <span className="font-gorditamedium">43</span> verified reviews
                            </p>
                          </div>
                          <div className="lg:block hidden right bg-[#1E1E1E] pt-10 px-10 pb-[4.375rem]">
                            <span className="inline-block w-[13.1875rem] h-[13.125rem] mb-[0.5rem]">
                              <StaticImage
                                src="../assets/img/edtech-menu.webp"
                                alt="Project Banner"
                                placeholder="blurred"
                                className="w-full h-full rounded-[0.625rem]"
                              />
                            </span>

                            <p className="text-sm leading-[1.5] mb-5 text-white">Discover our EdTech case study on implementing E-learning and LMS with seamless video calling and lag-free streaming, revolutionizing education</p>

                            <div className="link-green inline-flex flex-wrap items-center relative gap-[0.625rem] mt-4 group transition duration-500 ease-linear">
                              <Link to="/portfolio/online-learning-platform/" className="lg:text-sm relative leading-[1.5] italic font-gorditamedium text-[#4BD965] before:content-[''] before:w-full before:h-[1px] before:bg-[#4BD965] before:absolute before:left-0 before:bottom-0 " onClick={toggleMenu}>Read the case study
                              </Link>
                              <img
                                src={ArrowWhite}
                                alt=""
                                className="w-[0.4375rem] group-hover:translate-x-1 transition duration-500 ease-linear"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </li>

                <li
                  onMouseEnter={() => setIsShown('services')}
                  onMouseLeave={() => setIsShown('')}
                  className="group menu-item relative lg:px-4 md:px-2 md:my-0 my-3.5 md:py-[10px] md:text-center text-left md:w-auto w-full"
                >
                  <span
                    className=" md:text-black text-white md:text-base  max-sm:text-[22px] hover:text-primary font-gorditamedium group flex items-center"
                    onClick={() => getToggleCategoryClass("services")}
                  >
                    <span
                      className={`text-black cursor-pointer flex items-center after:relative after:ml-3 after:text-black md:after:hidden after:block relative before:content[''] before:absolute before:left-0 before:bottom-[-11px] before:w-0 before:h-[4px] before:bg-primary hover:before:w-[calc(100%-17px)] max-md:before:hidden ${selectedCategory["services"]
                        ? "after:content-['-']"
                        : "after:content-['+']"
                        } ${colorChange || !isWhiteNav
                          ? " md:text-black"
                          : "md:text-white"
                        } ${activePageClass('services')}`}
                    >
                      Services
                      <span className={`hidden md:flex ${colorChange || !isWhiteNav ? "" : "menu-arrow-white"}`}>
                        <BlackArrow className="ml-1.5 w-3 h-2 hidden md:block max-w-[0.688rem]" />
                      </span>
                    </span>
                  </span>
                  {(isShown === 'services' || (isMobile && selectedCategory["services"])) && (
                    <div
                      className={`transition-all duration-700 group-hover:opacity-100 group-hover:pointer-events-auto
                        md:opacity-0 opacity-100 z-10  md:w-[40.625rem] lg:w-[62rem] md:bg-[#fff] md:drop-shadow-[0px_32px_83px_rgba(0,0,0,0.07)] ${selectedCategory["services"] ? "block" : "hidden"
                        } md:block top-[2.8rem] rounded-[1.25rem] md:absolute md:left-1/2 py-0 md:pl-[15px] lg:pl-10  md:px-0 md:before:[''] md:before:block before:hidden before:absolute before:top-[-10px] before:left-1/2 before:translate-x-[-100%] before:w-0 before:h-0 before:border-l-[10px] before:border-l-transparent before:border-r-[10px] before:border-r-transparent before:border-b-[10px] before:border-b-[#fff] overflow-hidden md:translate-x-[-250px] lg:translate-x-[-440px] `}
                    >
                      <div
                        className={`md:block text-left ${selectedCategory["services"] ? "block" : "hidden"
                          }`}
                      >
                        <div className="main-grid grid lg:grid-cols-[auto_292px] gap-[2.375rem]">
                          <div className="left py-5 md:py-10">
                            <div className="md:w-auto w-full md:min-w-[30%] md:grid grid-cols-2 gap-y-[2rem] gap-x-[3.75rem]">
                              <Link to="/software-development-company/" className="grid grid-cols-[28px_auto] gap-4 menu-heading rounded-md p-3 group/1 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/software-dev-menu.inline.svg"
                                    alt="Software Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className=" text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/1:before:opacity-100  group-hover/1:before:duration-[0.4s] group-hover/1:before:ease-in-out group-hover/1:before:w-full">Software Development</h3>
                                  <p className="text-sm leading-[1.5]">Innovative software solutions tailored to your business needs.</p>
                                </div>
                              </Link>

                              <Link to="/web-development/" className="grid grid-cols-[28px_auto] gap-4 group/6 rounded-md p-3 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/web-dev-menu.inline.svg"
                                    alt="Web Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/6:before:opacity-100  group-hover/6:before:duration-[0.4s] group-hover/6:before:ease-in-out group-hover/6:before:w-full">Web Development</h3>
                                  <p className="text-sm leading-[1.5]">Robust web apps crafted for performance & user engagement</p>
                                </div>
                              </Link>

                              <Link to="/mobile-application-development/" className="grid grid-cols-[28px_auto] gap-4 group/2 rounded-md p-3 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/app-dev-menu.inline.svg"
                                    alt="Mobile App Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/2:before:opacity-100  group-hover/2:before:duration-[0.4s] group-hover/2:before:ease-in-out group-hover/2:before:w-full">Mobile App Development</h3>
                                  <p className="text-sm leading-[1.5]">Custom mobile apps built for performance and reliability</p>
                                </div>
                              </Link>

                              <Link to="/ui-ux-design-company/" className="grid grid-cols-[28px_auto] gap-4 group/5 rounded-md p-3 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/ui-ux-menu.inline.svg"
                                    alt="UI/UX Design"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/5:before:opacity-100  group-hover/5:before:duration-[0.4s] group-hover/5:before:ease-in-out group-hover/5:before:w-full">UI/UX Design</h3>
                                  <p className="text-sm leading-[1.5]">Intuitive designs focused on enhancing user interaction.</p>
                                </div>
                              </Link>

                              <Link to="/full-cycle-product-development/" className="grid grid-cols-[28px_auto] group/3 gap-4 rounded-md p-3 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/full-cycle-menu.inline.svg"
                                    alt="Full Cycle Product Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/3:before:opacity-100  group-hover/3:before:duration-[0.4s] group-hover/3:before:ease-in-out group-hover/3:before:w-full">Full Cycle Product Development</h3>
                                  <p className="text-sm leading-[1.5]">End-to-end development from concept to launch.</p>
                                </div>
                              </Link>

                              <Link to="/saas-development-company/" className="grid grid-cols-[28px_auto] gap-4 group/4 rounded-md p-3 hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer" onClick={toggleMenu}>
                                <span className="inline-block w-[1.875rem] h-[1.375rem]">
                                  <StaticImage
                                    src="../assets/img/saas-dev-menu.inline.svg"
                                    alt="SaaS Product Development"
                                    placeholder="blurred"
                                    className=""
                                  />
                                </span>
                                <div>
                                  <h3 className="text-base leading-[1.5] mb-1 font-gorditamedium inline-block relative before:w-0 before:content-[''] before:h-[2px] before:bg-black before:absolute before:left-0 before:bottom-0 before:opacity-0 before:duration-[0.4s] before:ease-in-out group-hover/4:before:opacity-100  group-hover/4:before:duration-[0.4s] group-hover/4:before:ease-in-out group-hover/4:before:w-full">SaaS Product Development</h3>
                                  <p className="text-sm leading-[1.5]">Scalable SaaS solutions for modern business challenges.</p>
                                </div>
                              </Link>
                            </div>
                            <p className="max-md:hidden flex flex-wrap items-baseline justify-start gap-[0.25rem] text-xs leading-[0.9375rem] mt-10 pt-[14px] border-t border-[#EAEAEA]">
                              <StaticImage
                                src="../assets/img/clutch.webp"
                                alt="Clutch"
                                placeholder="blurred"
                                className="inline-block w-[3.875rem] align-middle max-sm:mr-[0.3125rem]"
                              />
                              <span className="font-gorditamedium max-sm:align-middle max-sm:mr-[0.3125rem]">4.9/5</span>
                              <StaticImage
                                src="../assets/img/rating-stars.webp"
                                alt="Reviewed rating"
                                placeholder="blurred"
                                className="inline-block w-[3.875rem] align-middle ml-[0.375rem] "
                              />
                              based on <span className="font-gorditamedium">43</span> verified reviews
                            </p>
                          </div>
                          <div className="lg:block hidden right bg-[#1E1E1E] pt-10 px-10 pb-[4.375rem]">
                            <span className="inline-block w-[13.1875rem] h-[13.125rem] mb-[1.875rem]">
                              <StaticImage
                                src="../assets/img/service-menu.webp"
                                alt="Project Banner"
                                placeholder="blurred"
                                className="w-full h-full rounded-[0.625rem]"
                              />
                            </span>
                            <p className="text-sm leading-[1.5] mb-[1.875rem] text-white">Explore our portfolio of SaaS, Marketplace, EdTech, HealthTech, and AI products. Over 250+ projects delivered in 12 years.</p>

                            <div className="link-green inline-flex flex-wrap items-center relative gap-[0.625rem] mt-4 group transition duration-500 ease-linear">
                              <Link to="/portfolio/" className="lg:text-sm relative leading-[1.5] italic font-gorditamedium text-[#4BD965] before:content-[''] before:w-full before:h-[1px] before:bg-[#4BD965] before:absolute before:left-0 before:bottom-0 " onClick={toggleMenu}>Explore our success stories
                              </Link>
                              <img
                                src={ArrowWhite}
                                alt=""
                                className="w-[0.4375rem] group-hover:translate-x-1 transition duration-500 ease-linear"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </li>

                <li
                  onMouseEnter={() => setIsShown('technologies')}
                  onMouseLeave={() => setIsShown('')}
                  className="group menu-item relative lg:px-4 md:px-2 md:my-0 my-3.5 md:py-[10px] md:text-center text-left md:w-auto w-full"
                >
                  <span
                    className="[ md:text-black text-white md:text-base max-sm:text-[22px] hover:text-primary font-gorditamedium group flex items-center]"
                    onClick={() => getToggleCategoryClass("technology")}
                  >
                    <span
                      className={`text-black cursor-pointer flex items-center after:relative after:ml-3 after:text-black md:after:hidden after:block relative before:content[''] before:absolute before:left-0 before:bottom-[-11px] before:w-0 before:h-[4px] before:bg-primary hover:before:w-[calc(100%-17px)] max-md:before:hidden ${selectedCategory["technology"]
                        ? "after:content-['-']"
                        : "after:content-['+']"
                        } ${colorChange || !isWhiteNav
                          ? " md:text-black"
                          : "md:text-white"
                        } ${activePageClass('technology')}`}
                    >
                      Technology
                      <span className={`hidden md:flex ${colorChange || !isWhiteNav ? "" : "menu-arrow-white"}`}>
                        <BlackArrow className="ml-1.5 w-3 h-2 hidden md:block max-w-[0.688rem]" />
                      </span>
                    </span>
                  </span>
                  {(isShown === 'technologies' || (isMobile && selectedCategory["technology"])) && (
                    <div
                      className={`transition-all duration-700 group-hover:opacity-100 
                        md:opacity-0 opacity-100 z-10 md:w-[40.625rem] lg:w-[62rem] md:bg-[#fff] md:drop-shadow-[0px_32px_83px_rgba(0,0,0,0.07)] ${selectedCategory["technology"] ? "block" : "hidden"
                        } md:block top-[2.8rem] rounded-[1.25rem] md:absolute md:left-1/2 py-0 md:pl-[15px] lg:pl-10  md:px-0 md:before:[''] md:before:block before:hidden before:absolute before:top-[-10px] before:left-1/2 before:translate-x-[-100%] before:w-0 before:h-0 before:border-l-[10px] before:border-l-transparent before:border-r-[10px] before:border-r-transparent before:border-b-[10px] before:border-b-[#fff] overflow-hidden md:translate-x-[-380px] lg:translate-x-[-570px]`}
                    >
                      <div
                        className={`md:block text-left ${selectedCategory["technology"] ? "block" : "hidden"
                          }`}
                      >
                        <div className="grid lg:grid-cols-[auto_292px] gap-[1.5625rem] xl:gap-[2.375rem]">
                          <div className="left lg:py-[3.25rem] lg:pb-[2.25rem] md:py-[1.875rem] py-[1.5625rem]">
                            <div className="md:grid grid-cols-3 gap-[1.375rem] xl:gap-[1.875rem]">
                              <div className="md:w-auto w-full md:min-w-[30%]">
                                <div>
                                  <div className="p-[0.75rem] rounded-md bg-transparent md:hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer">
                                    <ul className="md:mb-5 mb-0">
                                      <li
                                        onClick={() => {
                                          getToggleMeClass("frontend-development")
                                        }}
                                        className={`text-black font-gorditamedium text-base leading-[1.87rem] mb-2 relative ${isExpanded["frontend-development"]
                                          ? "after:content-['-'] text-black"
                                          : "after:content-['+'] text-black"
                                          } after:relative after:ml-3 after:text-black md:after:hidden after:inline-block block`}
                                      >
                                        <Link to="/frontend-development-company/" onClick={toggleMenu}>
                                          <span className="inline-block w-[1.875rem] h-[1.375rem] mr-4 align-middle">
                                            <StaticImage
                                              src="../assets/img/frontend-menu.inline.svg"
                                              alt="Software Development"
                                              placeholder="blurred"
                                              className=""
                                            />
                                          </span>
                                          Frontend
                                        </Link>
                                      </li>
                                      <li
                                        className={`md:flex flex-col items-start block md:mb-0 mb-3 ml-[2.75rem] ${isExpanded["frontend-development"]
                                          ? "block"
                                          : "hidden"
                                          }`}
                                      >
                                        <Link
                                          to="/angular-js-development/"
                                          onClick={toggleMenu}
                                          className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                        >
                                          Angular&nbsp;JS
                                        </Link>
                                        <Link
                                          to="/react-js-development/"
                                          onClick={toggleMenu}
                                          className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                        >
                                          React&nbsp;JS
                                        </Link>
                                        <Link
                                          to="/vue-js-development/"
                                          onClick={toggleMenu}
                                          className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                        >
                                          Vue&nbsp;JS
                                        </Link>
                                        <Link
                                          to="/javascript-development-company/"
                                          onClick={toggleMenu}
                                          className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                        >
                                          Javascript
                                        </Link>
                                        <Link
                                          to="/tailwind-css-development-services/"
                                          onClick={toggleMenu}
                                          className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                        >
                                          Tailwind CSS
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                  <div>
                                    <ul className="p-[0.75rem] rounded-md bg-transparent md:hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer">
                                      <li>
                                        <div
                                          onClick={() => getToggleMeClass("cloud-development")}
                                          className={`text-black font-gorditamedium text-base leading-[1.87rem] mb-2 relative ${isExpanded["cloud-development"]
                                            ? "after:content-['-'] text-black"
                                            : "after:content-['+'] text-black"
                                            } after:relative after:ml-3 after:text-[#484848] md:after:hidden after:inline-block cursor-pointer block`}
                                        >
                                          <span className="inline-block w-[1.875rem] h-[1.375rem] mr-4 align-middle">
                                            <StaticImage
                                              src="../assets/img/cloud-menu.inline.svg"
                                              alt="Mobile App Development"
                                              placeholder="blurred"
                                              className=""
                                            />
                                          </span>
                                          Cloud
                                        </div>
                                      </li>
                                      <li
                                        className={`md:flex flex-col items-start block ml-[2.8125rem] ${isExpanded["cloud-development"]
                                          ? "block"
                                          : "hidden"
                                          }`}
                                      >
                                        <Link
                                          to="/aws-development-services/"
                                          onClick={toggleMenu}
                                          className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                        >
                                          AWS
                                        </Link>
                                        <Link
                                          to="/google-cloud-services/"
                                          onClick={toggleMenu}
                                          className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                        >
                                          Google Cloud
                                        </Link>
                                        <Link
                                          to="/azure-consulting-services/"
                                          onClick={toggleMenu}
                                          className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                        >
                                          Azure
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>

                              <div className="md:w-auto w-full md:min-w-[30%]">
                                <div className="md:mb-4 mb-0 p-[0.75rem] rounded-md bg-transparent hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer">
                                  <ul>
                                    <li>
                                      <Link
                                        to="/backend-development-company/"
                                        onClick={() => getToggleMeClass("backend-development")}
                                        className={` md:text-black font-gorditamedium text-base leading-[1.87rem] mb-2 relative ${isExpanded["backend-development"]
                                          ? "after:content-['-'] text-black"
                                          : "after:content-['+'] text-black"
                                          } after:relative after:ml-3 after:text-black md:after:hidden after:inline-block cursor-pointer block`}
                                      >
                                        <Link to="/backend-development-company/" onClick={toggleMenu}>
                                          <span className="inline-block w-[1.875rem] h-[1.375rem] mr-[0.875rem] align-middle">
                                            <StaticImage
                                              src="../assets/img/backend-menuu.inline.svg"
                                              alt="Software Development"
                                              placeholder="blurred"
                                              className=""
                                            />
                                          </span>
                                          Backend
                                        </Link>
                                      </Link>
                                    </li>
                                    <li
                                      className={`md:flex flex-col items-start block  ml-[2.75rem] ${isExpanded["backend-development"]
                                        ? "block"
                                        : "hidden"
                                        }`}
                                    >
                                      <Link
                                        to="/node-js-development/"
                                        onClick={toggleMenu}
                                        className="text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                      >
                                        Node.js
                                      </Link>
                                      <Link
                                        to="/net-core-development-company/"
                                        onClick={toggleMenu}
                                        className="text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                      >
                                        .NET Core
                                      </Link>
                                      <Link
                                        to="/python-development-company/"
                                        onClick={toggleMenu}
                                        className="text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                      >
                                        Python
                                      </Link>
                                      <Link
                                        to="/laravel-web-development/"
                                        onClick={toggleMenu}
                                        className="text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                      >
                                        Laravel
                                      </Link>
                                      <Link
                                        to="/php-web-development/"
                                        onClick={toggleMenu}
                                        className="text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                      >
                                        PHP
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="p-[0.75rem] rounded-md bg-transparent hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer">
                                  <ul>
                                    <li
                                      onClick={() => getToggleMeClass("trending")}
                                      className={` font-gorditamedium  block md:text-black font-gorditamedium text-base leading-[1.87rem] mb-2 relative  ${isExpanded["trending"]
                                        ? "after:content-['-'] text-black"
                                        : "after:content-['+'] text-black"
                                        } after:relative after:ml-3 after:text-black md:after:hidden after:inline-block`}
                                    >
                                      <span className="inline-block w-[1.875rem] h-[1.375rem] mr-4 align-middle">
                                        <StaticImage
                                          src="../assets/img/trending-menu.inline.svg"
                                          alt="Software Development"
                                          placeholder="blurred"
                                          className=""
                                        />
                                      </span>
                                      Trending
                                    </li>
                                    <li
                                      className={`md:flex flex-col items-start block ml-[2.75rem] ${isExpanded["trending"]
                                        ? "block"
                                        : "hidden"
                                        }`}
                                    >
                                      <Link
                                        to="/iot-application-development/"
                                        onClick={toggleMenu}
                                        className="text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                      >
                                        IOT
                                      </Link>
                                      <Link
                                        to="/blockchain-development/"
                                        onClick={toggleMenu}
                                        className="text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                      >
                                        Blockchain
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="md:w-auto w-full md:min-w-[6.875rem]">
                                <div className="p-[0.75rem] rounded-md bg-transparent hover:bg-[#F5F5F5] ease-linear duration-[0.4s] cursor-pointer">
                                  <ul>
                                    <li>
                                      <div
                                        onClick={() => getToggleMeClass("mobile-app-development-company")}
                                        className={`text-black font-gorditamedium text-base leading-[1.87rem] mb-2 relative ${isExpanded["mobile-app-development-company"]
                                          ? "after:content-['-'] text-black"
                                          : "after:content-['+'] text-black"
                                          } after:relative after:ml-3 after:text-[#484848] md:after:hidden after:inline-block cursor-pointer block`}
                                      >
                                        <span className="inline-block w-[1.875rem] h-[1.375rem] mr-4 align-middle">
                                          <StaticImage
                                            src="../assets/img/app-dev-menu.inline.svg"
                                            alt="Mobile App Development"
                                            placeholder="blurred"
                                            className=""
                                          />
                                        </span>
                                        Mobile
                                      </div>
                                    </li>
                                    <li
                                      className={`md:flex flex-col items-start block ml-[2.8125rem] ${isExpanded["mobile-app-development-company"]
                                        ? "block"
                                        : "hidden"
                                        }`}
                                    >
                                      <Link
                                        to="/ios-app-development/"
                                        onClick={toggleMenu}
                                        className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                      >
                                        iOS
                                      </Link>
                                      <Link
                                        to="/android-app-development/"
                                        onClick={toggleMenu}
                                        className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                      >
                                        Android
                                      </Link>
                                      <Link
                                        to="/flutter-app-development/"
                                        onClick={toggleMenu}
                                        className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                      >
                                        Flutter
                                      </Link>
                                      <Link
                                        to="/react-native-app-development/"
                                        onClick={toggleMenu}
                                        className=" text-black font-gorditaregular md:text-base md:leading-[1.5rem] text-[0.813rem] leading-[1.87rem] hover:text-black transition-all w-auto block md:inline-block duration-500 hover:font-gorditamedium relative before:content[''] before:absolute before:left-0 before:bottom-0 before:w-0 before:h-[2px] before:bg-black hover:before:w-full max-md:before:hidden mb-2"
                                      >
                                        React Native
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <p className="max-md:hidden flex flex-wrap items-baseline justify-start gap-[0.25rem] text-xs leading-[0.9375rem] mt-10 pt-[14px] border-t border-[#EAEAEA]">
                              <StaticImage
                                src="../assets/img/clutch.webp"
                                alt="Clutch"
                                placeholder="blurred"
                                className="inline-block w-[3.875rem] align-middle max-sm:mr-[0.3125rem]"
                              />
                              <span className="font-gorditamedium max-sm:align-middle max-sm:mr-[0.3125rem]">4.9/5</span>
                              <StaticImage
                                src="../assets/img/rating-stars.webp"
                                alt="Reviewed rating"
                                placeholder="blurred"
                                className="inline-block w-[3.875rem] align-middle ml-[0.375rem] max-sm:mr-[0.3125rem]"
                              />
                              based on <span className="font-gorditamedium">43</span> verified reviews
                            </p>
                          </div>
                          <div className="lg:block hidden right bg-[#1E1E1E] p-5 lg:p-[1.5625rem] xl:pt-10 xl:px-10 pb-[1.75rem]">
                            <span className="block w-[13.1875rem] h-[13.125rem] mb-[1.875rem] mx-auto">
                              <StaticImage
                                src="../assets/img/tech-menu.webp"
                                alt="Project Banner"
                                placeholder="blurred"
                                className="w-full h-full rounded-[0.625rem]"
                              />
                            </span>
                            <p className="text-base font-gorditamedium leading-[1.5] mb-[0.625rem] text-white">Secure & Scalable Architecture for Next-Generation App</p>
                            <p className="text-sm leading-[1.5] mb-5 text-white">Architecture built for high-performance web and mobile apps, utilizing microservices, containerization, and strong security protocols</p>

                            <div className="link-green inline-flex flex-wrap items-center relative gap-[0.625rem] mt-4 group transition duration-500 ease-linear">
                              <Link to="/technical-architecture-for-web-app-and-mobile-app/" className="lg:text-sm relative leading-[1.5] italic font-gorditamedium text-[#4BD965] before:content-[''] before:w-full before:h-[1px] before:bg-[#4BD965] before:absolute before:left-0 before:bottom-0 " onClick={toggleMenu}>See how we build
                              </Link>
                              <img
                                src={ArrowWhite}
                                alt=""
                                className="w-[0.4375rem] group-hover:translate-x-1 transition duration-500 ease-linear"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li className="group menu-item relative lg:mx-4 md:mx-2 md:my-0 my-3.5 md:text-center text-left md:w-auto w-full">
                  <Link
                    to="/portfolio/"
                    onClick={toggleMenu}
                    className={` text-black  group/before  cursor-pointer flex items-center after:relative after:ml-3 after:text-black md:after:hidden after:block relative before:content[''] before:absolute before:left-0 before:bottom-[-11px] before:w-0 before:h-[4px] before:bg-primary group-hover/before:before:w-[100%] max-md:before:hidden font-gorditamedium max-sm:text-[22px] ${colorChange || !isWhiteNav
                      ? " md:text-black"
                      : "md:text-white"
                      } ${activePageClass('portfolio')}`}
                  >
                    <span className="relative">Work</span>
                  </Link>
                </li>

                <li className="lg:mx-4 menu-item md:mx-2 md:my-0 my-3.5 md:text-center text-left md:w-auto w-full">
                  <Link
                    to="/company/"
                    onClick={toggleMenu}
                    className={`text-black  group/before  cursor-pointer flex items-center after:relative after:ml-3 after:text-black md:after:hidden after:block relative before:content[''] before:absolute before:left-0 before:bottom-[-11px] before:w-0 before:h-[4px] before:bg-primary group-hover/before:before:w-[100%] max-md:before:hidden font-gorditamedium max-sm:text-[22px]
                     ${colorChange || !isWhiteNav
                        ? " md:text-black"
                        : "md:text-white"
                      } ${activePageClass('company')}`}
                  >
                    <span className="relative">Company</span>
                  </Link>
                </li>

                <li className="lg:ml-4 md:ml-3 mr-0 md:my-0 my-3 md:w-auto w-full md:text-center text-left">
                  <Link
                    onClick={toggleMenu}
                    to="/contact-us/"
                    className={`inline-flex items-center uppercase justify-between inline-white border  shadow-1xl text-sm m-auto rounded-lg pl-5 pt-[9px] pb-[8px] font-gorditamedium leading-[1.313rem] group max-lg:pl-[9px] max-lg:text-[12px] pr-[2.25rem] relative hover:bg-primary hover:text-black hover:border-primary ${colorChange || !isWhiteNav ? 'border-black text-black hover:text-white' : 'text-white border-white max-sm:text-black max-sm:border-black'}`}
                  >
                    Contact Us
                    <span className="inline-flex justify-center items-center rounded-full ml-[0.625rem]  h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 border-2 border-transparent absolute right-[5px] top-[5px] ">
                      <img
                        src={ArrowWhite}
                        alt="ArrowRight"
                        className={`absolute left-1/2 top-[12px] -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px] ${colorChange || !isWhiteNav ? 'filter invert-[1] brightness-[1]' : 'max-sm:filter  max-sm:invert-[1] max-sm:brightness-[1]'}`}
                      />
                      <img
                        src={ArrowWhite}
                        alt="ArrowRight"
                        className={`absolute -left-16 top-[12px] -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2 w-[7px] h-[14px] ease-in duration-300`}
                      />
                    </span>
                  </Link>
                </li>
              </ul>
            </nav>
            {isMobile && <div className="px-4 pb-10">
              <div className="flex flex-col pb-5">
                <h3 className="font-blancomedium text-black pb-2 text-sm">
                  Inquires
                  <span className="inline-block text-orangecol">.</span>
                </h3>
                <a
                  href="mailto:sales@techuz.com"
                  className="text-[1.125rem] font-gorditamedium inline-block underline leading-[1.688rem] text-black transition-all duration-500 hover:font-gorditabold"
                >
                  sales@techuz.com
                </a>
              </div>
              <div className="flex flex-col pb-5">
                <h3 className="font-blancomedium text-black pb-2 text-sm">
                  Reach us at
                  <span className="inline-block text-orangecol">.</span>
                </h3>
                <div className="flex items-center pb-2">
                  <span className="w-7 h-7 flex item-center justify-center mr-2 rounded-full items-center border border-[#D9D9D9] ">
                    <StaticImage src="../assets/img/wp.png" alt="" />
                  </span>
                  <a
                    href="tel:9512559090"
                    className="text-base font-gorditamedium text-black transition-all duration-500 hover:font-gorditabold"
                  >
                    +91 95125 59090
                  </a>
                  <span className="text-sm font-gorditamedium text-black ml-1">( Sales )</span>
                </div>
                <div className="flex items-center">
                  <span className="w-7 h-7 flex item-center justify-center mr-2 rounded-full items-center border border-[#D9D9D9]">
                    <StaticImage src="../assets/img/wp.png" alt="" />
                  </span>
                  <a
                    href="tel:9512559091"
                    className="text-base font-gorditamedium text-black transition-all duration-500 hover:font-gorditabold"
                  >
                    +91 95125 59091
                  </a>
                  <span className="text-sm font-gorditamedium text-black ml-1">( HR )</span>
                </div>
              </div>
              <div className="flex flex-col pb-5">
                <SocialMediaIcons />
              </div>
            </div>}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
