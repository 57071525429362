import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import React from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"

config.autoAddCss = false

interface LayoutProps {
  children: React.ReactNode
  pageContext: {
    [key: string]: any
  }
}

const Layout = ({ children, pageContext }: LayoutProps) => {
  if (pageContext.layout === "special") return <main>{children}</main>
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
