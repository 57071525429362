import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import IndiaFlag from "../assets/img/india.inline.svg"
import USAFlag from "../assets/img/usa.inline.svg"

const CorporateLocs = () => {
  return (
    <div className="address-sec md:mt-5 mt-6 pb-1">
      <div className="container">
        <div className="border border-[#3D3D3D] flex flex-wrap md:p-0 py-8 px-6 rounded-[1.25rem]">
          <div className="md:border-r border-b md:border-b-0 border-[#3D3D3D] md:w-1/3 w-full flex md:p-6 p-0 pb-7 md:mb-0 mb-7 items-start">
            <USAFlag />
            <div className="pl-4 w-[calc(100%-32px)]">
              <h4 className="text-lg lg:text-xl font-blancobold text-white pb-4">
                USA - office
                <span className="bg-orangecol w-1.5 h-1.5 rounded-full inline-block ml-1" />
              </h4>
              <p className="text-[#979797] font-gorditaregular text-sm leading-7">
                10685-B Hazelhurst Dr. # 22194 <br />
                Houston - TX 77043 <br />
              </p>
            </div>
          </div>
          <div className="md:border-r border-b md:border-b-0 border-[#3D3D3D] md:w-1/3 w-full flex md:p-6 p-0 pb-7 md:mb-0 mb-7 items-start">
            <IndiaFlag />
            <div className="pl-4 w-[calc(100%-32px)]">
              <h4 className="text-lg lg:text-xl font-blancobold text-white pb-4">
                INDIA - headquaters
                <span className="bg-orangecol w-1.5 h-1.5 rounded-full inline-block ml-1" />
              </h4>
              <p className="text-[#979797] font-gorditaregular text-sm leading-7">
                1014-1020, 10th Floor, <br />
                i-Square Corporate Park, <br /> Science City Road, <br />
                Ahmedabad - 380060 <br />
              </p>
            </div>
          </div>
          <div className="md:w-1/3 w-full flex md:p-6 p-0 items-start">
            <StaticImage src="../assets/img/Singapore-flag2.png" alt="Singapore" className="w-8" />
            <div className="pl-4 w-[calc(100%-32px)]">
              <h4 className="text-lg lg:text-xl font-blancobold text-white pb-4">
                SINGAPORE - office
                <span className="bg-orangecol w-1.5 h-1.5 rounded-full inline-block ml-1" />
              </h4>
              <p className="text-[#979797] font-gorditaregular text-sm leading-7">
                1003 Toa Payoh Industrial Park <br />
                #03-1501 Singapore - 319075
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CorporateLocs
