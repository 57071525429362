exports.onRouteUpdate = ({ location }, pluginOptions = {}) => {
  if (process.env.NODE_ENV !== `production` || typeof gtag !== `function`) {
    return null
  }

  const pluginConfig = pluginOptions.pluginConfig
  const pathIsExcluded =
    typeof pluginConfig.exclude !== "undefined" &&
    pluginConfig.exclude.includes(location.pathname)
  if (pathIsExcluded) return null

  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    window.gtag(`event`, `page_view`, { page_path: pagePath })
  }

  const { delayOnRouteUpdate = 0 } = pluginConfig

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => setTimeout(sendPageView, delayOnRouteUpdate))
    })
  } else {
    // Delay by 32ms to simulate 2 requestOnAnimationFrame calls
    setTimeout(sendPageView, 32 + delayOnRouteUpdate)
  }

  return null
}
